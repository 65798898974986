
import { ChatBot, ChatBotChannel, ChatBotVM } from '@/models/chat-bot';
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import TwitchUser from '../test-forms/components/TwitchUser.vue';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';

import api$ from '@/services/tau-apis';
import { ChatBotStatusWsService } from '@/services/tau-api-ws';

export default defineComponent({
  name: 'ChatBot',
  components: {
    TwitchUser,
    Toast,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const data = computed(() => {
      const cb = store.getters['chatBots/all'] as ChatBot[];
      const channels = store.getters['chatBotChannels/all'] as ChatBotChannel[];
      return cb.map(
        (chatBot: ChatBot): ChatBotVM => ({
          ...chatBot,
          channels: channels
            ? channels.filter((channel) => channel.chat_bot === chatBot.id)
            : [],
        }),
      );
    });

    const displayAddModal = ref(false);
    const channelAddModal = ref(false);

    const url = ref('Loading...');

    const chatBotStatusWs = inject('chatBotStatusWs') as ChatBotStatusWsService;

    const newBot = reactive({
      user_id: '',
      user_name: '',
      user_login: '',
    });

    const newChannel: ChatBotChannel = reactive({
      chat_bot: '',
      channel: '',
    });

    function addChatBot() {
      url.value = 'Loading...';
      api$.tau
        .get<{ url: string }>('chat-bots/twitch-auth-link')
        .then((resp) => {
          url.value = resp.url;
        });
      displayAddModal.value = true;
    }

    function closeAddForm() {
      displayAddModal.value = false;
    }

    function copyUrl() {
      navigator.clipboard.writeText(url.value).then(() => {
        console.log('copied to clipboard');
        toast.add({
          severity: 'info',
          summary: 'Clipboard',
          detail:
            'Twitch Auth URL copied to your clipboard.  Please paste in another browser instance, and authenticate as the bot account.',
          life: 10000,
        });
        displayAddModal.value = false;
      });
    }

    const deleteBot = async (bot: ChatBot) => {
      await store.dispatch('chatBots/delete', bot);
    };

    const fetchChatBots = async () => {
      chatBotStatusWs.connect();
      await store.dispatch('chatBots/loadAll');
      await store.dispatch('chatBotChannels/loadAll');
    };

    const deleteChannel = async (channel: ChatBotChannel) => {
      await store.dispatch('chatBotChannels/delete', channel);
    };

    const addChannel = (bot: ChatBotVM) => {
      console.log('Adding Channel');
      newChannel.channel = '';
      newChannel.chat_bot = bot.id ? bot.id : '';
      channelAddModal.value = true;
    };

    const createChannel = async () => {
      newChannel.channel = `#${newChannel.channel}`;
      await store.dispatch('chatBotChannels/addOne', newChannel);
      channelAddModal.value = false;
    };

    onMounted(fetchChatBots);

    return {
      displayAddModal,
      channelAddModal,
      newBot,
      newChannel,
      data,
      url,
      copyUrl,
      addChatBot,
      closeAddForm,
      deleteBot,
      deleteChannel,
      addChannel,
      createChannel,
    };
  },
});
